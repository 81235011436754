




















import { Component, Vue } from "vue-property-decorator";
import FooterNav from "@/components/FooterNav.vue";
import HeadNav from "@/components/HeadNav.vue";
import TempScrollBox from "@/scrollbarWidth";

@Component({
  name: "VerticalSlider",
  components: { HeadNav, FooterNav },
  props: {
    siteMap: {},
    currSlide: {},
    whiteElem: {}
  }
})
export default class VerticalSlider extends Vue {
  mouseOverNavbar = false;
  scrollBarWidth = 0;
  mounted() {
    this.scrollBarWidth = new TempScrollBox().width;
  }
  logoClick() {
    this.$emit("logoClick");
  }
  changeMouseOverNavbar(value: boolean) {
    this.mouseOverNavbar = value;
  }
}
