















export default {
  name: "SocialLinks",
  props: {
    facebook: null,
    linkedin: null,
    youtube: null,
    twitter: null,
    twitch: null,
    discord: null
  }
};
