var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$store.state.base &&
      _vm.$store.state.base.home &&
      _vm.$store.state.base.home.services
  )?_c('div',{staticClass:"services"},[_c('div',{staticClass:"scaleHandler"},[_c('div',{staticClass:" wrapper"},[_c('div',{staticClass:"aside"},[_c('h2',[_vm._v(_vm._s(_vm.$t("home.services.title")))]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('home.services.desc[0]'))}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('home.services.desc[1]'))}})]),_c('ul',_vm._l((_vm.$store.state.base.home.services
            .servicesList),function(service,index){return _c('li',{key:index},[_c('router-link',{style:(service.image &&
                'background-image: url(' +
                  require('@/assets/images/home/services/' + service.image) +
                  ');'),attrs:{"to":'/about#' + service.id},domProps:{"innerHTML":_vm._s(service.title)}})],1)}),0),_c('div',{staticClass:"buttonMore"},[_c('router-link',{staticClass:"button secondaryButton reversed",attrs:{"to":"/about"}},[_vm._v(" "+_vm._s(_vm.$t("home.services.seeMore"))+" ")])],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }