import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueI18n from "vue-i18n";
import { messages, defaultLocale } from "@/i18n";
import VueKinesis from "vue-kinesis";
import Vue2TouchEvents from "vue2-touch-events";
import store from "@/store";
import Vuex from "vuex";
import VueCookies from "vue-cookies-ts";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);
Vue.use(VueCookies);
Vue.use(Vuex);
Vue.use(Vue2TouchEvents);

Vue.use(VueLodash, { lodash: { lodash } });
Vue.use(VueKinesis);
Vue.use(VueI18n);

const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
});

Vue.config.productionTip = false;

new Vue({
  router,

  i18n,
  store,
  render: h => h(App)
}).$mount("#app");
