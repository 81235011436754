






import { Component, Vue } from "vue-property-decorator";
import CaseStudyListing from "@/components/CaseStudies/CaseStudyListings.vue";

@Component({
  name: "Home",
  components: { CaseStudyListing }
})
export default class Home extends Vue {}
