

















import HeadNav from "@/components/HeadNav.vue";
import FooterNav from "@/components/FooterNav.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import CookiesNotice from "@/components/CookiesNotice.vue";
import axios from "axios";

@Component({
  name: "app",
  components: { CookiesNotice, FooterNav, HeadNav }
})
export default class App extends Vue {
  whiteMenu = false;
  disabledHeaderAndFooter = true;
  scrollbarY = this.disabledHeaderAndFooter ? "scroll" : "hidden";
  body: HTMLBodyElement | null = null;
  pixelRatio = 1;

  created() {
    window.addEventListener("resize", this.resizeHandler);
    this.pixelRatio = window.devicePixelRatio;
    const readLocale = this.$cookies.get("lang");
    const navigatorLanguage =
      window.navigator.language.slice(0, 2) === "pl" ? "pl" : "en";

    if (readLocale === "en" || readLocale === "pl" || navigatorLanguage)
      this.$store.dispatch("setLanguage", {
        lang: readLocale || navigatorLanguage,
        i18n: this.$i18n,
        cookies: this.$cookies
      });
  }

  mounted() {
    this.body = document.getElementsByTagName("body")[0];
    this.$store.commit("setCurrentRoute", this.$route);
    this.resizeHandler();

    this.$store.commit(
      "setBaseFile",
      require("@/i18n/" + this.$root.$i18n.locale + ".json") ||
        require("@/i18n/en.json")
    );
    if (this.$store.state.caseStudies.length < 1) this.fetchData();
  }

  fetchData() {
    axios
      .get(this.$store.state.apiUrl + "/case-studies?_sort=order")
      .then(response => {
        if (response.status === 200)
          this.$store.commit("setCaseStudies", response.data);
        else console.log(response.statusText);
      });
  }

  get pageTitle() {
    return this.$store.state.pageTitle;
  }

  @Watch("pageTitle")
  onPageTitleChange(value: string) {
    document.title = value;
  }

  get lang() {
    return this.$store.state.language;
  }

  @Watch("lang")
  langChanged() {
    this.fetchData();
  }

  @Watch("$route") // eslint-disable-next-line
  onPropertyChanged(value: any) {
    this.whiteMenu = value.meta.whiteMenu || false;
    this.disabledHeaderAndFooter =
      value.meta.headerAndFooterDisabled || false;
    this.$store.commit("setCurrentRoute", value);
    this.$store.commit("setMobileMenuVisibility", false);

    if (this.body) {
      if (value.hash) {
        Vue.nextTick(() => {
          const objToScroll = document.getElementById(value.hash.substr(1));

          if (this.body)
            this.body.scrollTop =
              (objToScroll && objToScroll.offsetTop - 85) || 0;
        });
      } else this.body.scrollTop = 0;
    }

    this.$store.commit("setSiteTitle", value.meta.title);
  }

  resizeHandler() {
    this.$store.commit("setViewPortDimensions", {
      width: window.innerWidth,
      height: window.innerHeight
    });

    // removes influence of user device scale factor
    const html = document.getElementsByTagName("html")[0];
    html.style.fontSize =
      (window.innerWidth > 2000
        ? 22
        : window.innerWidth > 1440
        ? 16
        : window.innerWidth > 1200
        ? 14
        : 12) + "px";
  }
}
