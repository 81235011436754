


















import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "CookiesNotice"
})
export default class CookiesNotice extends Vue {
  cookiesNotice = true;

  mounted() {
    if (
      this.$cookies.isKey("cookies") &&
      this.$cookies.get("cookies") == "accepted"
    ) {
      this.cookiesNotice = false;
    }
  }

  closeCookiesNotice() {
    this.$cookies.set("cookies", "accepted", { expires: "30D" });
    this.cookiesNotice = false;
  }

  @Watch("cookiesNotice")
  cookiesNoticeChanged() {
    this.$store.commit("setCookieNoticeAccepted", !this.cookiesNotice);
  }
}
