var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"social-links"},_vm._l((Object.entries(_vm.$props).filter(
      function (ref) {
          var key = ref[0];
          var value = ref[1];

          return value !== undefined && value !== {} && value !== null;
}
    )),function(ref){
    var key = ref[0];
    var value = ref[1];
return _c('a',{key:key,style:(("background-image: url(" + (require(("../assets/images/home/icon-" + key + ".svg"))) + ")")),attrs:{"href":value,"target":"_blank"}})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }