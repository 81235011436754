var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.siteMap)?_c('VerticalSlider',{directives:[{name:"touch",rawName:"v-touch:swipe.bottom",value:(_vm.changeSliderPos(-1)),expression:"changeSliderPos(-1)",arg:"swipe",modifiers:{"bottom":true}},{name:"touch",rawName:"v-touch:swipe.top",value:(_vm.changeSliderPos(1)),expression:"changeSliderPos(1)",arg:"swipe",modifiers:{"top":true}}],style:('--viewHeight: ' + _vm.sliderHeight + 'px;'),attrs:{"site-map":_vm.siteMap.length + _vm.homePageModifier,"home-page":_vm.homePage,"curr-slide":_vm.currentSlide,"white-elem":_vm.currentSlideWhiteBackground,"preload":_vm.preload,"id":"slider"},on:{"currentSlide":function($event){_vm.currentSlide = $event},"logoClick":function($event){_vm.currentSlide = 0}}},[_vm._l((_vm.siteMap),function(item,index){return _c('CaseStudyListingWithParallax',{key:item.id,class:[
      'slide',
      index + _vm.homePageModifier === _vm.currentSlide && 'active',
      index + _vm.homePageModifier === _vm.currentSlide - 1 && 'prev',
      index + _vm.homePageModifier === _vm.currentSlide + 1 && 'next'
    ],style:('top:' +
        ((index + _vm.homePageModifier - _vm.currentSlide) * _vm.sliderHeight +
          ((index === _vm.siteMap.length - 1 &&
            index + _vm.homePageModifier === _vm.currentSlide - 1 &&
            _vm.sliderHeight - _vm.footerHeight) ||
            0)) +
        'px;'),attrs:{"id":item.id,"listing-elem":item,"homePage":_vm.homePage}})}),(_vm.currentSlide < _vm.siteMap.length + _vm.homePageModifier)?_c('div',{class:['nav', _vm.currentSlideWhiteBackground && 'whiteBackground']},_vm._l((_vm.siteMap.length + _vm.homePageModifier),function(index){return _c('div',{key:index - 1,class:['circle', index - 1 === _vm.currentSlide && 'active'],on:{"click":function($event){_vm.currentSlide = index - 1}}})}),0):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }