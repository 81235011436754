


























import { VueTyper } from "vue-typer";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "HomeHero",
  components: { VueTyper }
})
export default class HomeHero extends Vue {}
