


















































































import { Component, Vue } from "vue-property-decorator";
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import router from "@/router";

const AppProps = Vue.extend({
  props: {
    parallax: {
      default: () => ({
        type: "translate",
        duration: 200,
        baseStrength: 20,
        perspective: 2500
      })
    },
    listingElem: {
      type: Object
    },
    detailsView: {},
    homePage: {}
  }
});

@Component({
  name: "CaseStudyListingWithParallax",

  components: { KinesisContainer, KinesisElement }
})
export default class CaseStudyListingWithParallax extends AppProps {
  mobileAnimationOffset = 10;

  caseStudyLink(id: string, link: string) {
    location.href =
      location.href.replace(router.currentRoute.hash, "") + "#" + id;
    router.push(link);
  }

  smallDesktop() {
    return (
      this.$store.state.viewPort.width > 1200 &&
      this.$store.state.viewPort.width < 1600
    );
  }

  get layers() {
    const {
      detailsHeroImageLayersMobile,
      detailsHeroImageLayers,
      listingImageLayersMobile,
      listingImagesLayers
    } = this.listingElem;
    const mobile = this.$store.state.viewPort.width < 1200;
    const details = this.$props.detailsView;

    if (
      details &&
      mobile &&
      detailsHeroImageLayersMobile &&
      detailsHeroImageLayersMobile.length > 0
    )
      return detailsHeroImageLayersMobile;
    else if (
      details &&
      detailsHeroImageLayers &&
      detailsHeroImageLayers.length > 0
    )
      return detailsHeroImageLayers;
    else if (
      mobile &&
      listingImageLayersMobile &&
      listingImageLayersMobile.length > 0
    )
      return listingImageLayersMobile;
    else return listingImagesLayers;
  }

  get parallaxLayers() {
    return this.layers.length;
  }

  get home() {
    return this.homePage || false;
  }
}
