














































































import { Component, Vue } from "vue-property-decorator";
import FooterNav from "@/components/FooterNav.vue";

@Component({
  name: "head-nav",
  components: { FooterNav },
  props: { white: Boolean }
})
export default class HeadNav extends Vue {
  body: HTMLBodyElement | null = null;
  lastScroll = 0;

  headLogoClick() {
    if (this.$store.state.currentRouteName.name === "Home")
      this.$emit("logoClick");
  }

  mouseOverNavbar(over: boolean) {
    this.$emit("mouseOverNavbar", over);
  }
}
