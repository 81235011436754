export default class TempScrollBox {
  /* This script allows to measure scrollbar
   width by creating temp scrollable box.
   use:  new TempScrollBox().width;     */

  scrollBarWidth = 0;
  constructor() {
    this.scrollBarWidth = 0;

    this.measureScrollbarWidth();
  }

  measureScrollbarWidth() {
    // Add temporary box to wrapper
    const scrollBox = document.createElement("div");

    // Make box scrollable
    scrollBox.style.overflow = "scroll";

    // Append box to document
    document.body.appendChild(scrollBox);

    // Measure inner width of box
    this.scrollBarWidth = scrollBox.offsetWidth - scrollBox.clientWidth;

    // Remove box
    document.body.removeChild(scrollBox);
  }

  public get width() {
    return this.scrollBarWidth;
  }
}
