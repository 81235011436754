var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"white",class:[
    _vm.$store.state.mobileMenuOpen && 'mobileMenuOpen',
    !_vm.$store.state.cookiesNoticeAccepted && 'cookiesNoticeNotAccepted'
  ],attrs:{"id":"nav"},on:{"mouseenter":function($event){return _vm.mouseOverNavbar(true)},"mouseleave":function($event){return _vm.mouseOverNavbar(false)}}},[_c('div',{staticClass:"wrapper"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/","alt":this.$t('headerNav.home')},nativeOn:{"click":function($event){return _vm.headLogoClick($event)}}}),_c('router-link',{staticClass:"logoSignet",attrs:{"to":"/","alt":this.$t('headerNav.home')},nativeOn:{"click":function($event){return _vm.headLogoClick($event)}}}),_c('a',{staticClass:"hamburgerMenu",on:{"click":function($event){return _vm.$store.commit('setMobileMenuVisibility', !_vm.$store.state.mobileMenuOpen)}}}),_c('div',{staticClass:"right"},[_c('div',{staticClass:"linkHandler"},[_c('router-link',{attrs:{"to":"/about"}},[_vm._v(_vm._s(_vm.$t("headerNav.aboutUs")))]),_c('router-link',{attrs:{"to":"/case-studies"}},[_vm._v(" "+_vm._s(_vm.$t("headerNav.caseStudies"))+" ")]),(_vm.$i18n.locale === 'pl' ? true : false)?_c('router-link',{attrs:{"to":"/career"}},[_vm._v(" "+_vm._s(_vm.$t("headerNav.career"))+" ")]):_vm._e(),(_vm.$i18n.locale === 'pl' ? true : false)?_c('router-link',{attrs:{"to":"/investor-relations/index"}},[_vm._v(" "+_vm._s(_vm.$t("footerNav.investorRelations"))+" ")]):_vm._e(),_c('router-link',{attrs:{"to":"/contact"}},[_vm._v(" "+_vm._s(_vm.$t("headerNav.contactUs"))+" ")]),(true)?_c('a',{staticClass:"langSwitcher",attrs:{"href":"#"},on:{"click":function($event){_vm.$i18n.locale === 'en'
              ? _vm.$store.dispatch('setLanguage', {
                  lang: 'pl',
                  i18n: _vm.$i18n,
                  cookies: _vm.$cookies
                })
              : _vm.$store.dispatch('setLanguage', {
                  lang: 'en',
                  i18n: _vm.$i18n,
                  cookies: _vm.$cookies
                })}}},[_vm._v(" "+_vm._s(_vm.$i18n.locale.toLocaleUpperCase())+" ")]):_vm._e()],1),(_vm.$store.state.viewPort.width < 1200)?_c('FooterNav',{staticClass:"menuFooter"}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }