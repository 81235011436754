



















































import { Component, Vue, Watch } from "vue-property-decorator";
import { caseStudy } from "@/interfaces";
import VerticalSlider from "@/components/VerticalSlider.vue";
import CaseStudyListingWithParallax from "@/components/CaseStudies/sections/CaseStudyListingWithParallax.vue";
import HomeHero from "@/components/Home/Home.Hero.vue";
import Services from "@/components/Home/Home.Services.vue";

@Component({
  name: "CaseStudyListing",
  components: {
    Services,
    HomeHero,
    CaseStudyListingWithParallax,
    VerticalSlider
  },
  props: { homePage: {} }
})
export default class CaseStudyListing extends Vue {
  currentSlide = 0;
  homePageModifier = 0;
  footerHeight = 0;
  currentSlideWhiteBackground: boolean | undefined = false;
  preload = true;
  slideInProgress = false;
  nextSlide = 0;
  sliderThreshold = 20;

  body: HTMLBodyElement | null = null;
  siteMap: caseStudy[] = [];

  sliderHeight = window.innerHeight;

  changeSliderPos(delta: number) {
    return () => this.slideChange(delta);
  }

  destroyed() {
    this.body?.removeEventListener("wheel", event => {
      this.handleWheel(event);
    });
    window.removeEventListener("resize", this.resizeHandler);
  }

  handleWheel(event: WheelEvent) {
    const deltaY = Math.abs(event.deltaY);
    const distance = deltaY > 20 ? deltaY : deltaY * 10;
    if (distance > this.sliderThreshold) {
      this.nextSlide = Math.sign(event.deltaY);
    }
  }

  resizeHandler() {
    this.sliderHeight = window.innerHeight;
    this.footerHeight = document.getElementById("foot")?.offsetHeight || 0;
  }

  mounted() {
    this.body = document.getElementsByTagName("body")[0];
    window.addEventListener("resize", this.resizeHandler);

    if (
      navigator.userAgent.toLowerCase().indexOf("firefox") > -1 &&
      !(navigator.userAgent.toLowerCase().indexOf("safari") > -1)
    )
      this.sliderThreshold = 2;

    this.body.addEventListener("wheel", event => {
      this.handleWheel(event);
    });

    this.body.addEventListener("keyup", event => {
      const code = event.code ? event.code : event.key;
      switch (code) {
        case "ArrowUp":
          this.nextSlide = -1;
          break;
        case "ArrowDown":
          this.nextSlide = 1;
          break;
        case "PageUp":
          this.nextSlide = -1;
          break;
        case "PageDown":
          this.nextSlide = 1;
          break;
        case "Home":
          this.currentSlide = 0;
          break;
        case "End":
          this.currentSlide = this.siteMap.length + this.homePageModifier;
          break;
        default:
          break;
      }
    });

    this.body.focus();
    this.updateSiteMap();
    this.footerHeight = document.getElementById("foot")?.offsetHeight || 0;

    this.$nextTick(() => {
      // prevents firing transitions on start
      setTimeout(() => {
        this.preload = false;
      }, 250);
    });
  }

  slideChange(direction: number) {
    if (this.$store.state.mobileMenuOpen) return;

    if (!this.sliderHeight) this.sliderHeight = window.innerHeight;

    switch (direction) {
      case 1:
        if (
          this.siteMap &&
          this.currentSlide < this.siteMap.length + this.homePageModifier
        )
          this.currentSlide++;
        break;
      case -1:
        if (this.currentSlide > 0) this.currentSlide--;
        break;
      default:
        break;
    }
  }

  @Watch("currentSlide")
  changedSlide() {
    if (this.siteMap)
      this.currentSlideWhiteBackground =
        this.siteMap[this.currentSlide + this.homePageModifier] &&
        this.siteMap[this.currentSlide + this.homePageModifier]
          .lightBackground;
  }

  @Watch("$store.state.caseStudies")
  updateSiteMap() {
    this.siteMap = this.$store.state.caseStudies.filter(
      (x: caseStudy) => x.language.language === this.$store.state.language
    );
    if (this.$props.homePage) {
      this.siteMap = this.siteMap?.filter(
        caseStudyElem => caseStudyElem.displayOnHomePage
      );
    } else {
      this.siteMap = this.siteMap?.filter(
        caseStudyElem => !caseStudyElem.displayOnHomePage
      );
    }
  }

  @Watch("nextSlide")
  slideChanger() {
    if (this.nextSlide !== 0) {
      this.slideChange(this.nextSlide);
      setTimeout(() => {
        this.nextSlide = 0;
      }, 500);
    }
  }
}
