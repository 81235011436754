


























import { Component, Vue } from "vue-property-decorator";
import SocialLinks from "@/components/SocialLinks.vue";

@Component({
  name: "FooterNav",
  components: { SocialLinks }
})
export default class FooterNav extends Vue {}
