import Vuex from "vuex";
import Vue from "vue";
import { caseStudy } from "@/interfaces";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiUrl: "https://cms.twohorizons.pl",
    viewPort: {
      width: 0,
      height: 0
    },
    mobileMenuOpen: false,
    cookiesNoticeAccepted: false,
    base: {},
    caseStudies: [],
    privacyPolicy: null,
    investorRelations: null,
    currentRouteName: "",
    language: "pl",
    pageTitle: "TwoHorizons S.A. | Producent gier | PC / XBOX / PS / NS",
    siteTitleBase: "TwoHorizons S.A. | Producent gier | PC / XBOX / PS / NS"
  },
  mutations: {
    setViewPortDimensions: (
      state,
      viewPortDimensions: { width: number; height: number }
    ) => {
      state.viewPort = {
        width: viewPortDimensions.width,
        height: viewPortDimensions.height
      };
    },
    setMobileMenuVisibility: (state, open: boolean) => {
      state.mobileMenuOpen = open;
    },
    setCookieNoticeAccepted: (state, accepted: boolean) => {
      state.cookiesNoticeAccepted = accepted;
    },
    setBaseFile: (state, base: JSON) => {
      state.base = base;
    },
    setCaseStudies: (state, cases) => {
      state.caseStudies = cases;
    },
    setPrivacyPolicy: (state, privacyPolicy) => {
      state.privacyPolicy = privacyPolicy;
    },
    setInvestorRelations: (state, investorRelations) => {
      state.investorRelations = investorRelations;
    },
    setCurrentRoute: (state, route: string) => {
      state.mobileMenuOpen = false;
      state.currentRouteName = route;
    },
    onLangChanged(state, payload) {
      if (payload.lang !== "undefined")
        payload.cookies.set("lang", payload.lang);
      payload.i18n.locale = payload.lang;
      state.language = payload.lang;
      state.base = require(`@/i18n/${payload.lang}.json`);
    },
    setSiteTitle: (state, title: string) => {
      state.pageTitle = `${state.siteTitleBase} - ${title}`;
    }
  },
  actions: {
    setLanguage(context, payload) {
      context.commit("onLangChanged", payload);
    }
  },
  getters: {
    getCaseStudies: state => state.caseStudies
  }
});
