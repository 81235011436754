import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      headerAndFooterDisabled: true,
      title: "Home"
    }
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      title: "About Us"
    }
  },
  {
    path: "/case-studies",
    name: "CaseStudies",
    component: () => import("../views/CaseStudies.vue"),
    meta: {
      headerAndFooterDisabled: true
    },
    children: [
      {
        path: "",
        name: "listing",
        component: () =>
          import("../components/CaseStudies/CaseStudyListings.vue"),
        meta: {
          displayOnHomePage: false,
          whiteBackground: false,
          hiddenOnListing: true,
          headerAndFooterDisabled: true,
          title: "Case studies"
        }
      },
      {
        path: ":case",
        component: () =>
          import("../components/CaseStudies/CaseStudy.Detail.vue"),
        meta: {
          caseStudyDetail: true
        }
      }
    ]
  },
  {
    path: "/career",
    name: "Career",
    component: () => import("../views/Career.vue"),
    meta: {
      whiteMenu: true,
      title: "Career"
    }
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
    meta: {
      whiteMenu: true,
      title: "Contact us"
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      whiteMenu: true,
      title: "Privacy Policy"
    }
  },
  {
    path: "/investor-relations/",
    redirect: "/investor-relations/index"
  },
  {
    path: "/investor-relations/:entry",
    name: "InvestorRelations",
    component: () => import("../views/InvestorRelations.vue"),
    meta: {
      whiteMenu: true,
      title: "Investor Relations"
    }
  },
  {
    path: "*",
    redirect: ""
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
