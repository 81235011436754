var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.listingElem)?_c('div',{staticClass:"caseStudyListing"},[_c('div',{staticClass:"scaleHandler"},[(_vm.listingElem.listingImagesLayers)?_c('div',{staticClass:"background",style:('--autoParallaxOffsett:' + _vm.mobileAnimationOffset + 'px;')},[_c('kinesis-container',{staticClass:"image-container",style:('--parallax: -' + _vm.parallaxLayers * _vm.parallax.baseStrength + 'px;'),attrs:{"duration":_vm.parallax.duration,"easing":"cubic-bezier(0.23, 1, 0.32, 1)","perspective":_vm.parallax.perspective,"active":_vm.$store.state.viewPort.width > 1200}},[_vm._l((_vm.layers),function(image,index){return _c('kinesis-element',{key:index,class:_vm.home && 'glitch glitch__preset',style:(("background-image: url( " + (_vm.$store.state.apiUrl + image.url) + " );")),attrs:{"type":_vm.parallax.type,"strength":(_vm.parallaxLayers - index) *
              _vm.parallax.baseStrength *
              (index % 2 > 0 ? 1 : -1),"tag":"div"}},_vm._l(((_vm.home && 5) || 0),function(i){return _c('div',{key:i,staticClass:"glitch__img"})}),0)}),_c('KinesisElement',{class:['overlay', _vm.detailsView && 'details'],attrs:{"tag":"div","strength":0}},[_c('div',{staticClass:" wrapper"},[_c('div',{staticClass:"center"},[(_vm.listingElem.overtitle)?_c('span',{staticClass:"overtitle"},[_vm._v(" "+_vm._s(_vm.listingElem.overtitle)+" ")]):_vm._e(),_c('h1',[_vm._v(_vm._s(_vm.listingElem.title))]),(_vm.listingElem.subtitle)?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.listingElem.subtitle)}}):_vm._e(),(
                  _vm.listingElem.caseId &&
                    _vm.$store.state.currentRouteName.path !==
                      _vm.listingElem.caseId &&
                    !_vm.listingElem.onlyExternalLink
                )?_c('router-link',{staticClass:"button primaryButton",attrs:{"to":'/case-studies/' + _vm.listingElem.caseId}},[_vm._v(" "+_vm._s(_vm.$t("caseStudies.readCaseStudy"))+" ")]):_vm._e(),(_vm.listingElem.externalLink)?_c('a',{staticClass:"button primaryButton external",attrs:{"href":_vm.listingElem.externalLink,"data-before":_vm.listingElem.externalLinkTitle ||
                    _vm.$t('caseStudies.readCaseStudy')}},[_vm._v(" "+_vm._s(_vm.listingElem.externalLinkTitle || _vm.$t("caseStudies.readCaseStudy"))+" ")]):_vm._e()],1)])])],2)],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }