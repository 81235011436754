












































import { VueTyper } from "vue-typer";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Services",
  components: { VueTyper }
})
export default class Services extends Vue {}
